<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('gallery') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 36px;padding-bottom: 120px;"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12 " v-for="item in list" :key="item.id">
            <img :src="$baseUploadURL2 + item.image" style="width: 100%;height: 300px;margin-bottom: 16px;" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },

  created() {
    this.$http2.get('gallery').then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>

<style></style>
